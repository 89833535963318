.press-detail-section{
  .title{
    text-align: center;
    margin: 40px;
  }
  .blog-description{
    padding: 20px;
    img {
      width: 100%;
    }
  }
}
.press-section {
  .press-row {
    .press-col {
      padding: 10px;
      display: flex;
      flex-direction: column;
      width: 100%;
      img {
        margin-bottom: 32px;
      }
      .link {
        text-transform: uppercase;
        font-size: 12px;
        margin-bottom: 6px;
        line-height: 14px;
        text-decoration: none;
        font-weight: 600;
      }
      .blog-description {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #6d6d6d;
        min-height: 80px;
      }
      .link-button {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .ant-btn {
          &:hover {
            background: rgb(54, 54, 54);
          }
        }
      }
    }
  }
}

.blog-screen {
  .top-categories {
    .category-links {
      .active {
        font-weight: 600;
        border-bottom: 3px solid black;
      }
    }
  }
}
