.btn {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  align-items: center;
  -webkit-animation: button-pop 0.25s ease-out;
  animation: button-pop 0.25s ease-out;
  -webkit-animation: button-pop var(--animation-btn, 0.25s) ease-out;
  animation: button-pop var(--animation-btn, 0.25s) ease-out;
  background-color: hsl(219 14% 28% / var(--tw-bg-opacity));
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  border-color: transparent;
  border-color: hsl(var(--n) / var(--tw-border-opacity));
  border-radius: 0.5rem;
  border-radius: var(--rounded-btn, 0.5rem);
  border-width: 1px;
  border-width: var(--border-btn, 1px);
  color: hsl(0 0% 100% / var(--tw-text-opacity));
  color: hsl(var(--nc) / var(--tw-text-opacity));
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  font-size: 0.875rem;
  font-weight: 600;
  height: 3rem;
  justify-content: center;
  line-height: 1.25rem;
  line-height: 1em;
  min-height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  text-decoration-line: none;
  text-transform: uppercase;
  text-transform: var(--btn-text-case, uppercase);
  transition-duration: 0.2s;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform,
    -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  user-select: none;
}

.btn-outline {
  --tw-text-opacity: 1;
  background-color: initial;
  border-color: currentColor;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  &:hover {
    background: #1a1a1a;
    span {
      color: white;
    }
  }
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.rounded-full {
  border-radius: 9999px;
}

.contact-block {
  display: flex;
  flex-direction: column;
  border: 0.0625rem solid #cecece;
  padding: 1.25rem 1.75rem;
  border-radius: 0.3125rem;
}
.contact-block .services-block__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.5rem;
  .services-block__row__icon {
    svg {
      width: 16px;
    }
  }
  .services-block__row__value {
    font-size: 13px;
  }
}
.contact-block .services-block__row__icon {
  flex-shrink: 0;
  width: 1.5625rem;
}
.contact-block .services-block__row__icon .icon {
  display: block;
  margin: 0 auto;
}
.list-disc {
  list-style-type: disc;
}
.p-6 {
  padding: 1.5rem;
}
.product-description-size {
  font-size: 13px;
  color: rgb(41, 41, 41);
  margin-bottom: 20px;
  background: #f6f6f6;
  padding: 25px;
  margin-top: 10px;
  ol,
  ul,
  menu {
    list-style: disc;
    margin: 0;
    margin-left: 20px;
  }
  .small-title {
    margin: 0px;
    padding: 0px;
  }
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(189 189 189 / var(--tw-border-opacity));
}
.border-b {
  border-bottom-width: 1px;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.pl-30p {
  padding-left: 30px;
}
.small-title {
  font-size: 0.8125rem;
  line-height: 1.25rem;
  text-transform: uppercase;
  text-align: left;
}
.min-w-350 {
  min-width: 350px;
}
.max-w-350 {
  max-width: 350px;
}
.mt-10 {
  margin-top: 10px;
}

.t-bold {
  font-weight: bold;
}
.ant-drawer-body {
  background: #ffffff !important;
}
.tab-bordered {
  --tw-border-opacity: 0.2;
  border-bottom-width: 2px;
  border-bottom-width: calc(var(--tab-border, 1px) + 1px);
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  border-style: solid;
}
.tab.tab-active {
  --tw-border-opacity: 1;
  --tw-text-opacity: 1;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
}
.tab,
.table {
  position: relative;
}
.tab {
  --tab-padding: 1rem;
  --tw-text-opacity: 0.5;
  --tab-color: hsla(var(--bc) / var(--tw-text-opacity, 1));
  --tab-bg: hsla(var(--b1) / var(--tw-bg-opacity, 1));
  --tab-border-color: hsla(var(--b3) / var(--tw-bg-opacity, 1));
  align-items: center;
  color: var(--tab-color);
  cursor: pointer;
  display: inline-flex;
  font-size: 0.875rem;
  height: 2rem;
  justify-content: center;
  line-height: 1.25rem;
  line-height: 2;
  padding-left: 1rem;
  padding-left: var(--tab-padding, 1rem);
  padding-right: 1rem;
  padding-right: var(--tab-padding, 1rem);
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
}
.tab,
.tabs {
  flex-wrap: wrap;
}
.align-middle {
  vertical-align: middle;
}

.max-w-\[1122px\] {
  max-width: 1122px;
}
.inline-block {
  display: inline-block;
}

.ant-image-preview-operations {
  background: none !important;
}
.ant-image-preview-operations-operation-rotateLeft {
  display: none;
}
.ant-image-preview-operations-operation-rotateRight {
  display: none;
}
.facebook-chat-div {
  width: 100%;
  position: absolute;
}

.ant-btn {
  &:focus-visible {
    outline: none !important;
  }
}
.ant-image-img {
  cursor: zoom-in;
}
.slider-nc {
  width: 100%;
  position: relative;
  cursor: zoom-in;
  .preview-button {
    position: absolute;
    background: none;
    width: 100px;
    height: 100px;
    display: flex;
    top: 42.5%;
    left: 40%;
    color: rgb(255, 255, 255);
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }
  .ant-image {
    width: 100%;
  }
  .image {
    width: 100%;
  }
  .buttonDiv {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 40px;
    .button {
      border-radius: 0px;
      border: 1px solid #ffffff;
      width: 160px;
      span {
        font-size: 14px;
        &:hover {
          color: #fff;
        }
      }
      color: black;
      height: 36px;
      margin: 0 auto;
      background-color: #ffffff;
      cursor: pointer;
      &:hover {
        background-color: black;
        color: #fff;
        border: none;
      }
    }
  }
  .buttonForProduct {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 40px;
    padding-right: 20px;
    .button {
      border-radius: 21px;
      span {
        font-size: 14px;
        &:hover {
          color: #fff;
        }
      }

      color: black;
      height: 36px;
      margin: 0 auto;
      background-color: #ffffff;
      cursor: pointer;
      &:hover {
        background-color: black;
        color: #fff;
      }
    }
  }
  .text {
    width: 100%;
    font-size: 30px;
    color: #ffffff;
    position: absolute;
    bottom: 80px;
    text-transform: uppercase;
    text-align: center;
  }
}

.text_position_left {
  width: 100%;
  height: 100%;
  div {
    width: 100%;
    text-align: left;
  }
}
.text_position_center {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  position: relative;
  div {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    margin: -25px 0 0 -25px; /* Apply negative top and left margins to truly center the element */
  }
}
.text_position_top {
  width: 100%;
  margin: 0 auto;
  div {
    width: 100%;
    text-align: center;
  }
}

.ant-image {
  .ant-image-mask {
    background: rgb(0 0 0 / 4%) !important;
    display: none !important;
  }
}

.slick-dots {
  bottom: 14px !important;
}
.slick-dots li.slick-active button:before {
  opacity: 1.75 !important;
  color: black !important;
}
.slick-dots li button:before {
  opacity: 0.5 !important;
}

.ant-image-preview-switch-right {
  .anticon {
    font-size: 26px !important;
  }
}
.ant-image-preview-switch-left {
  .anticon {
    font-size: 26px !important;
  }
}

.logo {
  .ant-image-img {
    cursor: pointer;
  }
}

.image-Modal-Slider {
  max-width: 100% !important;
  .ant-modal-content {
    background-color: rgba(70, 70, 70, 0.144);
    padding: 0px;
    position: relative;
    .ant-modal-header {
      position: absolute;
      top: 1px;
      width: 100%;
      background: #ffffff42;
    }
    .ant-modal-close {
      width: 40px;
      height: 40px;
      .ant-modal-close-x {
        border-radius: 5px;
        background: #adadad69;
        padding: 4px;
        .anticon-close {
          font-size: 22px;
          color: rgb(255, 255, 255);
        }
      }
    }
    .ant-modal-body {
      .ant-image {
        width: 100%;
      }

      .ant-carousel {
        .slick-slider {
          .slick-dots {
            li {
              button {
                &::before {
                  content: none;
                }
              }
            }
          }
          .slick-list {
            .slick-track {
              background: #0e0e0e73;
              .slick-slide {
                div {
                  .slider-image-web {
                    cursor: pointer;
                    object-fit: contain;
                    height: 96vh;
                  }
                  .slider-image-mobile {
                    cursor: pointer;
                    object-fit: contain;
                    height: 96vh;
                  }
                }
                div {
                  .next-button {
                    position: absolute;
                    top: 47%;
                    right: 5px;
                    z-index: 99999;
                    background: rgb(62 62 62 / 19%);
                    border-radius: 50%;
                    font-size: 28px;
                    display: flex;
                    line-height: 30px;
                    color: rgb(225 225 225 / 82%);
                    height: 50px;
                    width: 50px;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    flex-direction: row;
                  }
                  .prev-button {
                    position: absolute;
                    top: 47%;
                    left: 5px;
                    z-index: 99999;
                    background: rgb(62 62 62 / 19%);
                    border-radius: 50%;
                    font-size: 28px;
                    display: flex;
                    line-height: 30px;
                    color: rgb(225 225 225 / 82%);
                    height: 50px;
                    width: 50px;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    flex-direction: row;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.slick-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.text_align_center {
  text-align: center;
}
.text_element {
  padding-left: 10px;
  padding-right: 10px;
}

.left-align-items {
  margin-left: 100px;
}
.left-align-items-mobile {
  margin-left: 30px;
}

.mobile-navigation {
  ul {
    .up-down-button {
      span {
        line-height: 22px;
        padding-top: 3px;
      }
    }
  }
}

.total-active-products-count {
  display: block;
  float: right;
  position: absolute;
  right: 0px;
  top: 11px;
  font-size: 14px;
  color: gray;
}
