.checkout-form-section {
  form {
    .title {
      font-size: 14px;
      margin: 0px;
      padding-bottom: 0px;
      padding-top: 1px;
      margin-bottom: 16px;
    }
    .item-row {
      width: 100%;
      .ant-row {
        margin-bottom: 20px;
        width: 100%;
        .ant-col {
          // padding-top: 5px;
          // padding-bottom: 5px;
          label {
            font-size: 14px;
            margin-bottom: 6px;
            display: block;
          }
        }
      }
    
    }
  }
  .address-label{
    font-size: 14px;
    margin-bottom: 6px;
    display: block;
  }
}
