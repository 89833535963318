.checkout-section {
  width: 100%;
  .checkout-section-m-1122 {
    max-width: 1122px;
    margin: 0 auto;
    .products-section {
      background-color: #f5f5f5;
      width: 100%;
      padding: 20px;
      .product-rw {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        padding-bottom: 20px;
        border-bottom: 1px solid #e5e5e5;
        .product {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: stretch;
          .product-title {
            font-size: 12px;
            text-transform: uppercase;
          }
          .product-description {
            color: #3e3e3e;
            font-weight: 400;
            font-size: 13px;
          }
          img {
            margin-right: 20px;
          }
        }
        .action-container {
          width: 172px;
          display: flex;
          flex-direction: row;
          align-content: center;
          justify-content: space-between;
          align-items: center;
          .action {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-end;
          }
        }
      }
    }
    .cstep {
      .tab-bordered:after {
        grid-column-start: 1;
        grid-row-start: 1;
        height: 21px;
        width: 21px;
        place-items: center;
        place-self: center;
        border-radius: 9999px;
        background-color: white;
        border: 1px solid black;
        color: hsl(var(--bc) / var(--tw-text-opacity));
        content: "";
        position: absolute;
        top: 80%;
      }

      .tab-active:after {
        grid-column-start: 1;
        grid-row-start: 1;
        height: 21px;
        width: 21px;
        place-items: center;
        place-self: center;
        border-radius: 9999px;
        background-color: black;
        border: 1px solid black;
        color: hsl(var(--bc) / var(--tw-text-opacity));
        content: "";
        position: absolute;
        top: 80%;
      }
      .tabs {
        margin: 0px;
        height: 5rem;
        width: 100%;
        margin-bottom: 30px;
        padding: 0px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }
      .tab {
        padding-right: 12%;
        padding-left: 12%;
        height: 50px;
        border-top: none;
        border-left: none;
        border-right: none;
      }
    }
  }
}

.totals {
  width: 100%;
  margin-top: 20px;
  .item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 7px 1px;
    border-bottom: 1px solid #d9d9d9;
    height: 28px;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    .total {
      font-weight: 700;
    }
    .value {
      font-size: 13px;
    }
  }
}

.input-minus-plus-group {
  width: 80px;
  .ant-input-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    border-radius: 2px;
    border: 1px solid rgb(59, 59, 59);
    .ant-btn {
      width: 100%;
      background: white;
      border: none;
      text-align: center;
      font-size: 12px;
      color: #464646;
    }
    .ant-input {
      width: 100%;
      background: white;
      border: none;
      text-align: center;
      font-size: 14px;
      padding: 0px;

    }
  }
}
