.carousel-products {
  background: white;

  .slick-list {
    .carusel-product {
      height: auto;
      .ant-card-body {
        padding: 0px;
        text-align: center;
        .ant-btn {
          text-align: center;
          cursor: pointer;
          height: 39px;
          margin-top: 14px;
          text-transform: uppercase;
          color: black;
          font-size: 13px;
        }
      }
    }
  }
}
