.register-form {
  padding: 10px;
  h1 {
    font-size: 12px;
    text-transform: uppercase;
  }
  label{
    font-size: 14px;
  }
}
