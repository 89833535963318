.category-sort-section {
  width: 100%;
}
.filter-and-products {
  height: 100%;
  width: 100%;
  position: relative;
  padding-top: 44px;
  border: 1px solid rgb(255, 255, 255);
  display: block;
  .filter {
    position: absolute;
    z-index: 4;
    top: 0px;
    background: #fff;
    cursor: pointer;
    width: 99%;
    padding: 0 2px 1px 12px;
    .filter-dropdown {
      background: #fff;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      .filter-titles {
        .ant-space {
          margin-right: 20px;
        }
      }
      .variants {
        padding-top: 20px;
        padding-left: 4px;
        .variant {
          padding-top: 6px;
          padding-bottom: 5px;           
          &:hover{
            font-weight: 600;
          }
        }
      }
    }
  }
  .products {
    height: 100%;
    width: 100%;
    display: block;
    border-top: 1px solid rgb(206, 206, 206);
    padding-top: 10px;
    margin-top: 1px;
  }
}
