.items-right-mobile {
  .item {
    position: relative;
    .link {
      position: absolute;
      top: 44%;
      left: 33%;
      color: white;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: none;
      span {
        line-height: 12px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
    img {
      width: 240px;
      height: 220px;
      object-fit: cover;
    }
  }
}
