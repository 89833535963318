@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);

@font-face {
  font-family: "Helvetica";
  src: url("../assets/Helvetica-Font/Helvetica.woff") format("woff"),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
      url("../assets/Helvetica-Font/Helvetica.ttf") format("truetype");
  /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
  font-family: "Futura";
  src: url("../assets/futura/futurafont1.woff") format("woff");
  src: url("../assets/futura/futurafont2.woff") format("woff");
  src: url("../assets/futura/futurafont3.woff") format("woff");
  src: url("../assets/futura/futurafont4.woff") format("woff");
}

@font-face {
  font-family: "AvertaPE";
  src: url("../assets/averta/AvertaPE-Light.woff2") format("woff2"),
    url("../assets/averta/AvertaPE-Light.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AvertaPE";
  src: url("../assets/averta/AvertaPE-Light.woff2") format("woff2"),
    url("../assets/averta/AvertaPE-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AvertaPE";
  src: url("../assets/averta/AvertaPE-Regular.woff2") format("woff2"),
    url("../assets/averta/AvertaPE-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AvertaPE";
  src: url("../assets/averta/AvertaPE-RegularItalic.woff2") format("woff2"),
    url("../assets/averta/AvertaPE-RegularItalic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "AvertaPE";
  src: url("../assets/averta/AvertaPE-Bold.woff2") format("woff2"),
    url("../assets/averta/AvertaPE-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AvertaPE";
  src: url("../assets/averta/AvertaPE-Extrabold.woff2") format("woff2"),
    url("../assets/averta/AvertaPE-Extrabold.woff") format("woff"),
    format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

html,
body,
html * {
  font-family: AvertaPE, sans-serif;
}

body {
  font-family: AvertaPE, sans-serif;
  margin: 0;
  padding: 0;
}
.bg-white {
  background: white;
}
.ant-layout-content {
  background: white;
}

.this-is-it {
  height: 2px;
  width: 100%;
  background-color: #768fc5;
  margin: 0 auto;
  transition-property: width;
  transition-duration: 2s;
}

.this-is-it:hover {
  width: 100%;
}

//Colors
$background: #fefefe;
$text: #4b507a;

$cyan: #60daaa;
$yellow: #fbca67;
$orange: #ff8a30;
$red: #f45e61;
$purple: #6477b9;
$blue: #0eb7da;
$black: #000;

.center-effect {
  &:hover {
    color: $purple;
  }

  // Set up base styles, we're going to scale instead of animating width/height
  &::before,
  &::after {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform-origin: center; // Ensure scaling is done from the center (expands outwards)
  }

  // scale3d(<scale-horizontal>, <scale-vertical>, <scale-depth>);
  &::before {
    border-top: 2px solid $purple;
    border-bottom: 2px solid $purple;
    transform: scale3d(0, 1, 1); // Shrink only width
  }

  &::after {
    border-left: 2px solid $purple;
    border-right: 2px solid $purple;
    transform: scale3d(1, 0, 1); // Shrink only height
  }

  &:hover::before,
  &:hover::after {
    transform: scale3d(1, 1, 1); // Show full-size
    transition: transform 0.5s;
  }
}

.hvr-underline-from-left {
  border-bottom: 3px solid white;
  padding-bottom: 14px;

  &:hover {
    color: $purple;
    border-bottom: 3px solid black;
  }
}

.mobile-underline-from-left {
  border-bottom: 3px solid white;

  &:hover {
    color: $purple;
  }
}

.ch-underline {
  border-bottom: 3px solid white;
  padding-bottom: 1px;

  &:hover {
    color: $purple;
    border-bottom: 3px solid #d0d0d0;
  }
}

.fly-menu {
  background: #000;
}

.chmt-5 {
  margin-top: 4px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background: #fff;
}

.swiper-slide img {
  max-width: 100%;
  max-height: 100%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
}

.swiper-button-next,
.swiper-button-prev {
  color: white !important;
}

.product-page-horizontal-layout {
  max-width: 90rem;
  margin-left: auto !important;
  margin-right: auto !important;
  background: white;
  padding-top: 10px;
  padding-bottom: 40px;
  .ant-card-body {
    padding: 0px;
    padding-top: 22px;
    .ant-card-meta {
      .ant-card-meta-detail {
        .ant-card-meta-title {
          font-size: 13px;
          text-transform: uppercase;
          margin-bottom: 2px;
          padding-bottom: 2px;
        }
        .ant-card-meta-description {
          font-size: 14px;
          color: #000;
        }
      }
    }
  }
}

.footer-section {
  background-color: black;
  width: 100%;
  padding-top: 40px;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.center-items {
  align-items: center;
}

.gap-4 {
  gap: 1rem;
}

.p-10 {
  padding: 2.5rem;
}

.mb-4 {
  margin-bottom: 4px;
}

.text-hover-white:hover {
  color: white;
}

.text-hover-bold-white:hover {
  color: white;
  font-weight: bold;
}

.page-horizontal-layout {
  background-color: black;
  width: 100%;
  max-width: 90rem;
  margin-left: auto !important;
  margin-right: auto !important;
}

.w-48 {
  width: 48px;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: inherit;
  color: currentColor;
}

.relative {
  position: relative;
}

.w-full {
  width: 100%;
}

.h-full {
  width: 100%;
}

.h-screen {
  height: 100vh;
}

.z-10 {
  z-index: 10;
}

.text-white {
  color: #fff;
}

.absolute {
  position: absolute;
}

.font-semibold {
  font-weight: 500;
}

.mt-2 {
  margin-top: 2px;
}

.uppercase {
  text-transform: uppercase;
}

.h-59 {
  height: 59px;
}

.object-cover {
  background-size: cover;
}

.inset-0 {
  inset: 0;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.max-width-layout {
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.text-base-content {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
}

.p-10 {
  padding: 2.5rem;
}

.rounded {
  border-radius: 0.25rem;
}

@media (min-width: 48rem) {
  .footer {
    grid-auto-flow: column;
  }
}

.footer {
  display: grid;
  width: 100%;
  grid-auto-flow: row;
  place-items: start;
  row-gap: 2.5rem;
  column-gap: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-gray-50 {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}

.font-bold {
  font-weight: bold !important;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(189 189 189 / var(--tw-text-opacity));
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.link-hover {
  text-decoration-line: none;
}

.link {
  cursor: pointer;
  text-decoration-line: underline;
}

.footer > * {
  display: grid;
  place-items: start;
  gap: 0.5rem;
}

.footer {
  display: grid;
  width: 100%;
  grid-auto-flow: row;
  place-items: start;
  row-gap: 2.5rem;
  column-gap: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.gap-4 {
  gap: 1rem;
}

.items-center {
  align-items: center;
}

.flex-row {
  flex-direction: row;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-4 {
  margin-top: 1rem;
}

.justify-center {
  justify-content: center;
}

.items-end {
  align-items: flex-end;
}

.text-center {
  text-align: center;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.px-6x2 {
  padding-left: 3.8rem;
  padding-right: 3.8rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

@media (min-width: 720px) {
  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
}

.tracking-widest {
  letter-spacing: 0.1em;
}

.font-semibold {
  font-weight: 600;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mb-0 {
  margin-bottom: 0px;
}

.home-category-link-btn {
  margin-top: 2px;
  width: 197px;
  height: 44px !important;
  margin-bottom: 1rem;
  max-width: 200px;
  font-weight: 400;
  font-size: 15px;
  color: #000000;
  border-radius: 0px;
  background: #fff;
  border-radius: 0px !important;
  &:hover {
    color: #ffffff !important;
    background: #000 !important;
  }
}

.home-category-link-btn-mobile {
  font-size: 13px;
  margin-top: 2px;
  height: 38px !important;
  margin-bottom: 1rem;
  max-width: 200px;
  font-weight: 400;
  color: #000000;
  border-radius: 0px;
  background: #fff;
  border-radius: 0px !important;
  &:hover {
    color: #ffffff !important;
    background: #000 !important;
  }
  span {
    padding: 2px 10px;
    font-size: 12px;
  }
}

.homecategory-btn-figma-mobile {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.homecategory-btn-figma {
  font-weight: 600;
  font-size: 38px;
  line-height: 54px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.slider-item-left-web {
  bottom: 30px;
  left: 100px;
  display: flex;
  flex-direction: column;
}

.slider-item-left-mobile {
  bottom: 23%;
  left: 30px;
  display: flex;
  flex-direction: column;
}

.margin-0 {
  margin: 0px;
}

.padding-0 {
  padding: 0px;
}

.font-36 {
  font-size: 36px;
}

.justify-end {
  justify-content: flex-end;
}

.mt-10 {
  margin-top: 2.5rem;
}

.w-20 {
  width: 5rem;
}

.h-20 {
  height: 5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.h-16 {
  height: 4rem;
}

.w-auto {
  width: auto;
}

.h-12 {
  height: 3rem;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.fly-menu-container {
  position: absolute;
  top: 125.5px;
  left: 0px;
  z-index: 99999;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: center;
  justify-content: space-between;
  background: black;
  .fly-menu {
    border: 1px solid #000;
    border-top: 1px solid #000;
    border-bottom: 3px solid #000;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px;
    z-index: 100;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: center;
    justify-content: space-between;
    padding: 10px;
    gap: 60px;
    max-width: 90rem;
    margin-left: auto !important;
    margin-right: auto !important;

    .items-right {
      display: flex;
      column-gap: 11px;

      .item {
        position: relative;
        a {
          font-weight: bold;
          text-transform: uppercase;
          text-decoration: none;
          cursor: pointer;
          font-size: 19px;
          min-width: 30px;
          margin-top: 4px;
          display: block;
          margin-bottom: 6px;
          position: absolute;
          color: white;
          left: 40%;
          top: 46%;

          &:hover {
            color: $black;
          }
        }
      }
    }

    .items-left {
      display: flex;
      flex-direction: column;

      .items {
        -webkit-column-gap: 19px;
        column-gap: 20px;
        display: flex;
        flex-wrap: wrap;

        .item {
          a {
            color: #fff;
            font-weight: normal;
            text-transform: uppercase;
            text-decoration: none;
            cursor: pointer;
            font-size: 11px;
            line-height: 26px;
            min-width: 30px;
            display: block;
            margin-bottom: 6px;
            border-bottom: 3px solid #000;
            &:hover {
              color: rgb(222, 222, 222);
            }
          }
        }
      }
    }
  }
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.main-navigation {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 1px solid #d6d6d6;
  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    align-content: center;
    column-gap: 30px;
    li {
      cursor: pointer;
      height: 44px;
      min-width: 30px;
      &:hover {
        border-bottom: 3px solid black;
      }
    }
    .active-nav {
      border-bottom: 3px solid rgb(219, 219, 219);
    }
  }
}

.main-navigation ul > li span {
  font-size: 14px;
  display: block;
  height: 52px;
  letter-spacing: 1px;
  font-variant-ligatures: none;
  padding: 0 10px;
  position: relative;
  text-transform: uppercase;
  z-index: 19;
  font-weight: 400;
  &:hover {
    color: $black;
  }
}

.justify-between {
  justify-content: space-between;
}

.mt-0\.5 {
  margin-top: 0.125rem;
}

.text-small {
  font-size: 12px;
}

.cursor-pointer {
  cursor: pointer;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.h-6 {
  height: 6rem;
}

.w-6 {
  width: 6rem;
}

.left-items {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  margin-right: 14px;
  margin-top: 18px;
  padding-left: 18px;

  a {
    padding: 5px 8px;
    font-size: 28px;
  }
}

.right-items {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  gap: 8px;
  float: right;
  margin-right: 14px;
  margin-top: 18px;
  padding-right: 22px;

  .quick-menus {
    gap: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .ant-badge {
    .ant-badge-count {
      background: black;
    }
  }
}

.right-align-items {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.center-align-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-bar-menu {
  background-color: #ffffff;
  border: 1px solid white;
  padding: 6px;
  width: 60px;
  margin-top: 4px;
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    border: 1px solid gray;
  }
}

.mobile-navigation {
  background-color: white;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    flex-direction: row;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(209, 209, 209);

    .logo {
      width: 140px;
    }

    .close-button {
      background-color: #f7f7f7;
      border: none;
      font-size: 16px;
      width: 47px;
      text-align: center;
      z-index: 99999;
      color: #000;
      &:focus-visible {
        border: none;
        outline: none;
        color: #000;
      }
      &:hover {
        border: none;
        outline: none;
        background-color: rgb(216, 216, 216);
        color: #000;
      }
      svg {
        &:hover {
          border: none;
          outline: none;
          color: #000;
        }
        &:focus {
          border: none;
          outline: none;
          color: #000;
        }
      }
    }
    &:focus-visible {
      border: none;
    }
  }

  .close-icon {
    &:hover {
      border: none;
      outline: none;
    }
  }

  ul {
    width: 100%;

    li {
      cursor: pointer;
      text-transform: uppercase;
      .item-name {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      span {
        font-size: 15px;
        font-weight: 500;
        line-height: 34px;
      }

      ol {
        background-color: #ffffff;
        padding-left: 10px;

        li {
          line-height: 32px;

          a {
            &:hover {
              font-weight: bold;
              color: #000;
            }
          }
        }
      }
    }
  }
}

.close-icon {
  cursor: pointer;
  &:hover {
    border: 1px solid rgb(158, 158, 158);
  }
}

.category-loader {
  display: flex;
  width: 100%;
  align-content: center;
  align-items: center;
  height: 50vh;
  justify-content: center;
}

.empty-category {
  display: flex;
  width: 100%;
  align-content: center;
  align-items: center;
  height: 50vh;
  justify-content: center;

  h2 {
    color: #5c5c5c;
    font-size: 14px;
  }
}

.add-cart-button {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  border-bottom-left-radius: 9999px;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  border-right-width: 2px;
  border-style: solid;
  border-top-left-radius: 9999px;
  display: flex;
  flex-direction: row;
  width: 80%;
  color: hsl(var(--pc) / var(--tw-text-opacity));
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  font-size: 0.875rem;
  font-weight: 600;
  height: 3rem;
  justify-content: center;
  line-height: 1.25rem;
  line-height: 1em;
  min-height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  text-decoration-line: none;
  text-transform: uppercase;
  text-transform: var(--btn-text-case, uppercase);
  transition-duration: 0.2s;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  border-right: 2px solid #ebebeb;

  &:hover {
    color: #ebebeb !important;
    border-color: gray !important;
    border-right: 2px solid #ebebeb !important;
    background-color: rgb(0 0 0 / 0.6);
  }

  .icon {
    width: 1.75rem;

    &:hover {
      border: 1px solid #ebebeb;
    }
  }

  .text {
    margin-left: 0.25rem;
  }
}

.cart-buttons-mobile {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  .add-wishlist-button {
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
    border-bottom-right-radius: 9999px;
    border-color: rgb(0 0 0 / var(--tw-border-opacity));
    border-top-right-radius: 9999px;
    width: 73px;
    color: hsl(var(--pc) / var(--tw-text-opacity));
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    align-content: center;
    flex-shrink: 0;
    flex-wrap: wrap;
    font-size: 0.875rem;
    font-weight: 600;
    height: 3rem;
    justify-content: center;
    line-height: 1.25rem;
    line-height: 1em;
    min-height: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    text-decoration-line: none;
    text-transform: uppercase;
    text-transform: var(--btn-text-case, uppercase);
    transition-duration: 0.2s;
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke, opacity, box-shadow, transform,
      filter, backdrop-filter, -webkit-transform, -webkit-filter,
      -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-user-select: none;
    user-select: none;

    &:hover {
      color: #ebebeb !important;
      border-color: gray !important;
      background-color: rgb(0 0 0 / 0.6);
    }

    .icon {
      width: 1.75rem;
    }
  }
}

.cart-buttons {
  display: flex;
  width: 99%;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;

  .add-wishlist-button {
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
    border-bottom-right-radius: 9999px;
    border-color: rgb(0 0 0 / var(--tw-border-opacity));
    border-top-right-radius: 9999px;
    width: 73px;
    color: hsl(var(--pc) / var(--tw-text-opacity));
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    align-content: center;
    flex-shrink: 0;
    flex-wrap: wrap;
    font-size: 0.875rem;
    font-weight: 600;
    height: 3rem;
    justify-content: center;
    line-height: 1.25rem;
    line-height: 1em;
    min-height: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    text-decoration-line: none;
    text-transform: uppercase;
    text-transform: var(--btn-text-case, uppercase);
    transition-duration: 0.2s;
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke, opacity, box-shadow, transform,
      filter, backdrop-filter, -webkit-transform, -webkit-filter,
      -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-user-select: none;
    user-select: none;

    &:hover {
      color: #ebebeb !important;
      border-color: gray !important;
      background-color: rgb(0 0 0 / 0.6);
    }

    .icon {
      width: 1.75rem;
    }
  }
}

.product-contact {
  display: flex;
  flex-direction: column;
  border: 0.0625rem solid #cecece;
  padding: 1.25rem;
  border-radius: 0.3125rem;

  p {
    margin: 0;
    padding: 0;
  }
}

.product-detail-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .product-detail-images {
    margin-right: 10px;
    width: 100%;
    background: none;
    padding: 12px;
    .ant-row {
      display: flex;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
      .ant-col {
        background: none;
      }
    }
  }

  .description-line {
    padding: 20px;
    width: 100%;
  }

  .slick-slide img {
    height: auto;
  }
}

.product-detail-screen {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  .product-detail-images {
    margin-right: 10px;
    width: 100%;
    background: white;
    padding: 12px;

    .ant-row {
      display: flex;
      align-content: center;
      justify-content: flex-start;
      align-items: center;

      .ant-col {
        background: none;
        .ant-image {
          width: 100%;
        }
      }
    }
  }
}

.grid-block-products {
  //
  .ant-row {
    .ant-col {
      padding: 40px 20px;

      .grid-block-products-button {
        display: flex;
        border-radius: 9999px;
        background: white;
        border: none;
        width: 84px;
        height: 36px;
        flex-direction: row;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        color: black;
        float: right;
        margin-top: 48px;
        margin-right: 14px;
        &:hover {
          font-weight: bold;
          background: rgb(0 0 0 / 8%);
        }
        svg {
          width: 16px;
        }
      }
    }
  }

  h2 {
    font-style: normal;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
  }
}

.language-selection-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 45px;
  margin: 0px;
  float: left;
  border: white;
  padding: 1px;
  height: auto;
  &:hover {
    color: blue;
    border-color: gray !important;
  }
}

.language-selection-list {
  .close-btn {
    position: absolute;
    left: 4px;
    top: 4px;
  }
  .header {
    height: 44px;
    width: 100%;
    padding: 1px 10px;
    text-align: center;
    position: relative;
    .text {
      text-transform: uppercase;
      font-weight: bold;
    }
    .choose-btn {
      position: absolute;
      top: -1px;
      right: 34px;
      font-size: 16px;

      .icon {
        height: 44px;
      }
    }
  }
  .languages {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-left: -23px;
    margin-right: -23px;
    .ant-btn {
      width: 100%;
      border-radius: 0px;
      height: 44px;
    }
    .topbtn {
      border-top: 1px solid #afafaf !important;
      border-bottom: 1px solid #afafaf !important;
    }
    .bottombtn {
      border-bottom: 1px solid #afafaf !important;
    }

    .active {
      color: #040404;
      font-weight: bold;
    }
  }

  li {
    &:hover {
      color: #040404;
      font-weight: bold;
      cursor: pointer;
    }

    .close-icon {
      float: right;
      top: 33px;
      right: 10px;
      position: absolute;
      border: none;
      font-size: 15px;

      &:hover {
        color: #040404;
        font-weight: bold;
        cursor: pointer;
        border: 1px solid #b3b3b3;
      }
    }
  }
}

.login-btn {
  border: none;
  background: white;
}

.circle-logo {
  &:hover {
    background: #e3e3e3;
    border-radius: 999px;
    cursor: pointer;
  }
}

.reset-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  height: 100%;
  position: relative;
  .reset-form {
    width: 100%;
    .form-control {
      .label {
        margin-top: 8px;
        display: block;
        font-size: 12px;
      }
      .ant-input {
        margin-top: 10px;
        border-radius: 0px;
        margin-bottom: 5px;
      }
    }
    .ant-btn {
      width: 100%;
    }
  }
}

.login-form-section {
  max-width: 300px;
  margin: 0 auto;

  .login-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 6px;
    height: 100%;

    .ant-btn {
      width: 100%;
      text-transform: uppercase;
      font-size: 12px;
    }
    .account {
      font-size: 14px;
      text-transform: uppercase;
      color: #3e3e3e;
      max-width: 170px;
      text-align: center;
    }
    .required-information {
      display: block;
      font-size: 11px;
      width: 100%;
    }
    .forgot-password {
      width: 100%;
      text-align: right;
      padding: 0px;
      margin-bottom: 10px;
    }
    .privacy-policy {
      width: 100%;
      font-size: 12px;
      margin-top: 22px;
      ul {
        list-style: disc;
        margin-left: 17px;
        li {
        }
      }
    }
    .customer-service {
      width: 100%;
      font-size: 12px;
      margin-top: 22px;
      h2 {
        font-size: 14px;
      }
    }

    .inputs {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      margin-bottom: 20px;
      .form-control {
        .label {
          margin-top: 8px;
          display: block;
          font-size: 12px;
        }
        .ant-input {
          margin-top: 10px;
          border-radius: 0px;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.wishlistEmpty {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
  border-color: rgb(224 224 224 / var(--tw-border-opacity));
  border-style: dashed;
  border-width: 1px;
  color: rgb(224 224 224 / var(--tw-text-opacity));
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;
  height: 330px;
  justify-content: center;
  line-height: 1.75rem;
  padding: 0.5rem;
  text-align: center;
  max-width: 300px;
  min-width: 300px;
  margin: 5px;
  float: left;
}
.screen-loader {
  margin: 20px;
  min-height: 330px;
  text-align: center;
  margin-top: 16%;
}

.register-form {
  .input-register {
    margin-top: 8px;
  }
  label {
    margin-bottom: 8px;
  }
  .phone-number {
    margin-bottom: 8px;
    label {
      margin-bottom: 8px;
    }
  }
  .gender-dob {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    .gender {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
    }
    .dob {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}
.profile-section {
  margin-top: 40px;
  margin-bottom: 60px;
  font-size: 12px;
  .username {
    padding-left: 20px;
  }
  .ant-row {
    .ant-col {
      .list-orders {
        width: 100%;
        margin-top: 10px;
        height: 43px;
        padding-top: 5px;
        padding-bottom: 5px;
        border-bottom: 0.5px solid #d9d9d9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .points-onoo {
          float: left;
          font-weight: bold;
          color: black;
        }
        .list-orders-button {
          float: right;
          font-weight: bold;
          color: black;
          text-transform: uppercase;
          text-decoration: underline;
        }
      }
      padding: 20px;
      p {
        font-size: 12px;
      }
      .profile-section-title {
        border-bottom: 1px solid #91919163;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        h5 {
          font-size: 13px;
          text-transform: uppercase;
          color: rgb(19, 19, 19);
        }
      }
    }
  }
}
.profile-edit-section {
  position: relative;
  .header {
    height: 30px;
    background: #ffffff;
    width: 100%;
    display: flex;
    font-size: 8px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    text-transform: uppercase;
    border-bottom: 1px solid #b5b5b5;
    margin-top: -14px;
    .cancel-button {
      border: none;
    }
  }
}
.action-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;

  .save-button {
    font-size: 13px;
    height: 36px;
    border: none;
    box-shadow: none;
    margin: 4px;
    margin-right: 0px;
  }
}
.label-text {
  margin-bottom: 4px;
  color: #161616;
  margin-top: 12px;
  font-size: 13px;
  display: block;
}
.profile-input {
  border-radius: 1px;
}
.layout-single-product {
  color: #161616;
  .ant-card-body {
    padding: 0px;
    margin: 0px;
    .grid-block-products-button {
      padding: 0px;
      position: absolute;
      bottom: 20px;
      right: 10px;
    }
  }
}

.layout-single-product-description {
  position: relative;
  color: #161616;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;

  a {
    max-width: 460px;
    text-align: center;
    line-height: 16px;
    font-size: 13px;
    &:hover {
      color: #000;
      font-weight: bold;
    }
  }
}

.top-categories {
  width: 100%;
  .page-category-title {
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
  }
  .category-links {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .link {
      font-size: 13px;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 500;
      margin: 10px;
      margin-left: 4px;
      margin-right: 4px;
      margin-top: 2px;
      padding: 11px;
      height: 33px;
      padding-bottom: 4px;
      margin-bottom: 4px;
    }
  }

  .category-description {
    max-width: 576px;
    font-size: 13px;
    line-height: 16px;
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 42px;
    margin-top: 42px;
  }
}

.blog {
  width: 100%;
  height: 100vh;
  .blog-header {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;

    .blog-title {
      font-size: 13px;
      text-transform: uppercase;
      margin: 40px 20px;
      &:hover {
        font-weight: bold;
        color: #000;
      }
    }
  }
}
.information-box {
  p {
    font-size: 13px;
    line-height: 18px;
    margin: 0px;
    display: block;
  }
  .uppercase {
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 8px;
  }
  .icons {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 20px;
    .icon-box {
      width: 33%;
      text-align: center;
      img {
        height: 46px;
        margin: 0 auto;
        display: block;
      }
      span {
        font-size: 11px;
        line-height: 12px;
        display: block;
        height: 20px;
      }
    }
  }
}
.gift-box {
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  width: 100%;
  .title {
    padding: 0px;
    margin: 0px;
    font-size: 13px;
  }
  .description {
    font-size: 13px;
    color: #3e3e3e;
  }
  img {
    width: 100%;
  }
}

.leaflet-container {
  width: 100%;
  height: 100vh;
  max-height: 600px;
  margin-top: 46px;
}
.boutique-timetable {
  list-style: none;
  li {
    display: flex;
    flex-direction: row;
    .days {
      width: 80px;
    }
    .time {
      width: 140px;
    }
  }
}

.leaflet-marker-icon {
  background: transparent !important;
  border: none !important;
  width: 40px !important;
  height: 40px !important;
}

.body-measurment {
  .ant-form-item {
    .ant-row {
      .ant-col {
        .ant-form-item-control-input {
          .ant-input-affix-wrapper {
            .ant-input {
              width: 100%;
            }
          }
        }
      }
      .ant-col {
        label {
          width: 150px !important;
        }
      }
    }
  }
}
.search-button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 40px;
  align-items: center;
  width: 92px;
  span {
    height: 25px;
  }
}

.product-detail-mobile {
  .slick-next {
    right: 0 !important;
  }

  .slick-prev {
    left: 0px !important;
  }
}

.points-list-table-container {
  width: 100%;
  padding: 20px;
  font-size: 13px;
  .point-list-table {
    width: 100%;
    thead {
      tr {
        .th-tbl {
          text-align: left;
          padding-bottom: 10px;
          padding-top: 10px;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 10px;
        }
      }
    }
  }
}

.empty-cart {
  margin: 40px auto;
  padding: 97px;
  width: 100%;
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  svg {
    font-size: 34px;
    margin-bottom: 10px;
  }
}

.flex-direction-column-align-items-center {
  align-items: center;
  display: flex;
  flex-direction: column;
  div {
    padding-top: 20px;
  }
}

a {
  color: #000000;
}
a:active {
  color: #000000;
}

a:hover {
  color: #000000;
}

.card-similar-products {
  .ant-card-body {
    .ant-card-meta {
      .ant-card-meta-detail {
        .ant-card-meta-title {
          font-size: 12px;
        }
      }
    }
  }
}


.youtube-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.youtube-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}