.order-list-table-container {
  padding: 10px 19px;
  max-height: 500px;  
  overflow-y: auto;
  scrollbar-width: 4px;
  scrollbar-color: rgb(0, 0, 0) rgb(0, 0, 0);
  display: block;
  margin-bottom: 80px;
  .order-list-table {
    width: 100%;
    thead {
      tr {
        background: black;
        th {
          color: white;
          padding: 5px;
          text-transform: uppercase;
          border: none;
          width: 25%;
          text-align: center;
        }
      }
    }
    tbody {
      background: #f5f5f5;
      tr {
        td {
          text-align: center;
          font-weight: 400;
          font-size: 13px;
          border-bottom: 1px solid #d9d9d9;
          .order-product {
            display: flex;
            padding: 22px 10px;
            img {
              margin-right: 14px;
            }
            .desc {
              text-align: left;
              .product {
                text-transform: uppercase;
                color: black;
                font-weight: bold;
                padding: 0px;
                margin: 0px;
                font-size: 12px;
                margin-bottom: 10px;
              }
              .product_code {
                padding: 0px;
                margin: 0px;
                font-size: 12px;
              }
              .original_price {
                padding: 0px;
                margin: 0px;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
