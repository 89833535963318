.checkout-confirmation {
  font-size: 14px;
  .shipment-section {
    color: #545454;
    font-size: 13px;
  }
  .gifting {
    background: #f5f5f5;
    width: 100%;
    text-transform: uppercase;
    padding: 20px;
    margin-top: 24px;
    font-size: 13px;
    .title {
      width: 100%;
      font-weight: bold;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
    }
    .row1 {
      font-size: 13px;
      width: 100%;
      border-top: 1px solid #d1d1d1;
      padding-top: 10px;
      padding-bottom: 10px;
      .text-area{
        margin-top: 10px;
        border-radius: 0px;
      }
    }
    .row {
      font-size: 13px;
      width: 100%;      
      padding-top: 10px;
      border-top: 1px solid #d1d1d1;
    }
  }
}
