.auth-form-drawer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  height: 100%;
  max-width: 320px;
  margin: 0 auto;
  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .circle-logo {
      width: 50px;
      height: 50px;
    }
    .account {
      color: #3e3e3e;
      font-size: 14px;
      text-transform: uppercase;
    }
  }
  .required-information {
    display: block;
    font-size: 11px;
    width: 100%;
  }
  .login-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    form {
      width: 100%;
    }
    .inputs {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      margin-top: 20px;
      width: 100%;
      .form-control {
        .label {
          .label-text-alt {
            display: block;
            font-size: 12px;
            margin-top: 8px;
          }
        }
        .ant-input {
          border-radius: 0;
          margin-bottom: 5px;
          margin-top: 10px;
        }
      }
    }
    .ant-btn {
      width: 100%;
      margin-top: 8px;
      margin-bottom: 8px;
    }
    .ant-btn-text {
      text-align: right;
    }
    .privacy-policy {
      font-size: 12px;
      margin-bottom: 8px;
      margin-top: 40px;
    }
  }
  .customer-service {
    margin-top: 8px;
    font-size: 12px;
    margin-bottom: 8px;
    h2 {
      font-size: 14px;
    }
  }
}

.one-time-action {
  color: black !important;
  font-size: 14px;
}
