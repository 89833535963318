.red-box {
  background: #ff0000;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 480px;
  .items {
    .pointnumber-pointtext {
      margin: 0px;
      .pointnumber {
        font-size: 36px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .pointtext {
        font-size: 18px;
        margin-left: 5px;
        font-weight: 400;
        text-transform: uppercase;
      }
    }

    .line {
      height: 4px;
      width: 250px;
      background: rgb(0, 0, 0);
      margin-bottom: 10px;
    }
    .unlock-text {
      font-size: 12px;
    }
    .member-id {
      font-size: 14px;
      margin-top: 10px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}

.verify-form {
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  min-height: 320px;
  margin-top: 100px;
  h2 {
    font-size: 16px;
  }
  p {
    font-size: 13.4px;
  }
  .ant-form {
    width: 100%;
    .ant-row {
      .ant-col {
        padding: 0px;
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-input-affix-wrapper {
              .ant-input {
                text-align: center;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
    .resend-button {
      margin-top: 10px;
      margin-bottom: 20px;
      float: right;
    }
  }
}
