.wishlist-container {
  padding-bottom: 100px;
  padding-top: 40px;
  .wishlist-list {
    .title {
      margin: 20px 6px;
    }
    .ant-row {
      .ant-col {
        position: relative;
        padding: 5px;
        span {
          position: absolute;
          top: 0px;
          right: -20px;
          height: 40px;
          width: 40px;
          svg {
            height: 20px;
            width: 20px;
            color: gray;
            border: 1px solid #c7c7c7;
            border-radius: 5px;
            cursor: pointer;
            background: #f1f1f1;
          }
        }
      }
    }
  }
}
