.product-detail-section {
  width: 100%;
  .product-name {
    display: block;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.271rem;
    line-height: 1.875rem;
  }
  .short_description {
    display: block;
    font-size: 0.8125rem;
    line-height: 1.3125rem;
    font-weight: 400;
    margin-top: 0.625rem;
    color: #757575;
  }
  .price {
    font-size: 1.1224rem;
    font-weight: 500;
    line-height: 1.3125rem;
    color: #444444;
    margin: 4px 0px;
    .discounted-detail{
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
    .discount-prc{
      font-size: 12px;
    }
  }
  .product-amount{
    font-size: 0.8125rem;
    line-height: 1.3125rem;
    color: #444444;
  }
  .reference {
    font-size: 0.8125rem;
    line-height: 1.3125rem;
    color: #444444;
  }
}

.features_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  .feature_description {
    color: #1c1c1c;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px;
  }
  .size-guide {
    color: #505050;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    margin-top: 14px;
  }
  .selection {
    color: #444444;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .ant-btn {
      color: #444444;
      border-radius: 0px;
      height: 7px;
      padding: 0px;
      line-height: 14px;
      width: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      background: black;
      color: white;
      border: none;
    }
    .amount_no_0 {
      color: #b7b4b4;
      border: 1px solid #ededed;
    }
  }
}
