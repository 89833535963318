.filter-section {
  width: 100%;
  padding: 10px;

  .left-right-items {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .left-item {
    }
    .right-item {
    }
  }

  .filter-items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .filter-items-container {
      width: 100%;
      max-width: 280px;
      .filter-title {
        font-size: 13px;
        color: #6c6c6c;
      }
    }
  }
  .filter-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    li {
      padding: 10px;
      padding-left: 0px;
      .selected-filters {
        font-weight: bold;
        text-decoration: underline;
        .title {
          padding: 1px;
          color: #444444;
          cursor: pointer;
          &:hover {
            font-weight: bold;
          }
        }
        .ant-btn {
          padding: 1px;
        }
      }
      .title {
        padding: 1px;
        color: #444444;
        cursor: pointer;
        &:hover {
          font-weight: bold;
        }
      }
      .count {
        padding: 1px;
        color: #747474;
        cursor: pointer;
        &:hover {
          font-weight: bold;
        }
      }
      .ant-btn {
        padding: 1px;
      }
    }
  }
}

.filter-action-item {
  padding: 10px;
  display: flex;
  padding-left: 0px;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  gap: 10px 64px;
  max-height: 219px;
  .column {
    margin-right: 44px;
  }
}
